<template>
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              <b>{{$t('actions.support.title')}}</b>
            </div>

            <div class="subtitle-1 font-weight-light">
              
            </div>
          </template>

          <v-form>
            <v-container class="py-0">

              <v-row>
                <v-col
                  cols="12"
                >

                <div class="text-h3 mb-3 mt-3">{{$t('actions.support.contactInformation')}}</div>
         
              <table>
                <tr>
                  <td class="pr-5"><v-icon>mdi-phone</v-icon></td>
                  <td>+32 472 85 57 03</td>
                </tr>
                <tr>
                  <td><v-icon>mdi-email</v-icon></td>
                  <td><a href="mailto:support@paybonsai.com">support@paybonsai.com</a></td>
                </tr>
              </table>

              <div class="mt-6 text-h3">{{$t('actions.support.sendUsAMessage')}}</div>

              <v-textarea 
                :label="$t('actions.support.yourMessage')" 
                v-model="message"
                />

                <v-btn color="primary" :loading="sending" :disabled="!message || sending" @click="send">{{$t('actions.support.sendMessageBtn')}}</v-btn>
                
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </base-material-card>
        
</template>

<script>
  export default {
    data() {
      return {
        message: "",
        sending: false
      }
    },
    
    methods: {
      send() {
        this.sending = true
        this.$api.sendSupportquestion(this.message)
          .then(() => {
            this.$showSuccess(this.$t('actions.support.messageSent'))
            this.message = ""
          })
          .finally(() => {
            this.sending = false
          })
      }
    }
  }
</script>
